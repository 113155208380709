/* Login.css */

body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('./Synagogue.jpeg'); /* Adjust the path accordingly */
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

.login {
  background-color: rgba(255, 255, 255, 0.8); /* Adds a semi-transparent background to the form */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.google-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 8px;
}

.login button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4285F4; /* Google Blue */
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.login button:hover {
  background-color: #357AE8; /* Darker Google Blue */
}

.login h3 {
  margin-bottom: 20px;
  font-size: 24px;
}
