.event-booking {
  width: 85%;
  max-width: 600px;
  padding: 30px;
  background: white;
  height: 100%;
  margin: 50px 0;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.event-booking h2 {
  text-align: center;
  margin-bottom: 20px;
  
}

.event-booking form {
  margin-top: -15px;

  display: flex;
  flex-direction: column;
}

.event-booking input,
.event-booking select,
.event-booking option,
.event-booking textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-picker {
  margin-bottom: 25px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.text {
  margin-bottom: 10px;
}
.see-prices-button{
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

}

.custom-datepicker .occupied-date {
  border-radius: 25%;
  color: white;
  background-color: #ffcccc !important;
  color: #ff0000 !important;
}

.custom-datepicker .approved-deposit-date {
  border-radius: 25%;
  color: white;
  background-color: #ffcccc !important;
  color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}

.custom-datepicker .approved-paid-date {
  border-radius: 25%;
  color: white;
  background-color: #ffcccc !important;
  color: #ff0000 !important;
  border: 1px solid #070707 !important;
}
