.close-button-container {
  position: relative;
  align-self: flex-start;
  display: inline-block; /* Ensures the button is not stretched */
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 24px;
  height: 24px;
}

.confirmation-message {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  text-align: center;
  width: 300px;
  border-radius: 10px;
}

.confirm-button {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 3px;
  transition: background-color 0.3s ease;
}

.confirm-button:hover {
  background-color: #0056b3;
}
