.payment-instruction {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px;
  height: max-content;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 50px;
}

.payment-instruction h2, .payment-instruction h3 {
  margin-bottom: 10px;
  text-align: center;
}

.payment-instruction p {
  margin-bottom: 10px;
  text-align: justify;
  line-height: 1.5;
  font-size: 16px;
  margin: 0 10px;
  padding: 0 10px;
}

.payment-instruction h2 {
  font-size: 24px;
  text-align: center;
}

.payment-instruction h3 {
  font-size: 20px;
  font-weight: bold;
}

.transfer {
  background: #e7e4e4;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  align-self: center;

}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

p {
  margin-right: 10px;
}

.dropzone {
  width: 80%;
  height: 100px;
  border: 2px dashed #007BFF;
  background-color: #F0F8FF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: center;

}

.uploaded-file {
  margin-top: 10px;
}



.upload-button, .skip-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4c87af;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.upload-button:hover, .skip-button:hover {
  background-color: #427394;
}

.payment-options {
  margin: 10px 0;
}

.paypal-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paypal-button-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
