.prayer-times-container {
  
  padding: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prayer-times {
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background for better readability */
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.prayer-times h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.prayer-times h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.prayer-section {
  margin: 20px 0;
}

.prayer-section h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

.prayer-section ul {
  list-style-type: none;
  padding: 0;
  font-size: 20px;
}

.prayer-section li {
  margin: 10px 0;
}
