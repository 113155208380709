/* styles/Donation.css */
.donation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  width: 85%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-top: 50px;
}

.donation h2 {
  margin-bottom: 20px;
}

.donation form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.donation input,
.donation textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.donation button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4b4b4b;
  color: white;
  cursor: pointer;
}

.donation button:hover {
  background-color: #3669f4;
}


