/* App.css */

/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  height: 100%;
  background-image: url('./Synagogue.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 100%;
  max-width: 600px;
  padding: 10px;
}

.rtl {
  direction: rtl;
  text-align: right;
}

.ltr {
  direction: ltr;
  text-align: left;
}

.App h1 {
  margin-bottom: 10px;
}

.App p {
  margin-bottom: 20px;
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's on top of other elements */
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: right;
  direction: rtl;
}

.app-title, .user-greeting {
  flex: 1;
  text-align: center;
}

.language-button, .home-button {
  margin-right: 5px;
  background-color: #313331;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.language-button:hover {
  background-color: #707070;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
  margin-right: auto;
}

.button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-primary {
  background-color: #4CAF50;
  color: white;
}

.button-primary:hover {
  background-color: #454ba0;
}

.button-danger {
  background-color: #f44336;
  color: white;
}

.button-danger:hover {
  background-color: #d32f2f;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  right:20px;

  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
}

.billboard {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
