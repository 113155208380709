.termsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80%;
}

.terms-of-use {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  margin-top: 45px;
  padding: 20px;
  width: 85%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  position: relative; /* Make sure CloseButton is positioned correctly */
}

.terms-of-use h2 {
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: 24px;
}

.terms-of-use p {
  margin-bottom: 10px;
  text-align: justify;
  line-height: 1.5;
  font-size: 16px;
}

.terms-of-use .termsButton {
  margin: 20px;
  border-radius: 15px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.terms-of-use .termsButton:hover {
  background-color: #0056b3;
}

.signature {
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.signatureCanvas {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
