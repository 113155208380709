.manager-dashboard {
  padding: 30px;
  background: white;
  height: max-content;
  margin: 50px 0;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Dropdown menu styling */
.dashboard-header select {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.dashboard-header select:focus {
  outline: none;
  border-color: #007BFF;
}

.dashboard-header select option {
  padding: 10px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
}

.events-table, .members-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -15px;

}

.events-table th, .events-table td, .members-table th, .members-table td {
  border: 1px solid #ddd;
  padding: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.events-table th, .members-table th {
  background-color: #dad6d6;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.events-table tr:nth-child(even), .members-table tr:nth-child(even) {
  background-color: #fafafa;
}

.events-table tr:hover, .members-table tr:hover {
  background-color: #f1f1f1;
}

.save-changes-button, .invite-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
  margin-right: 10px;
}

.save-changes-button:hover, .invite-button:hover {
  background-color: #0056b3;
}



@media (max-width: 768px) {
  .events-table, .members-table {
    display: flex;
    flex-direction: column;
  }

  .events-table thead, .members-table thead {
    display: none;
  }

  .events-table tbody tr, .members-table tbody tr {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }

  .events-table td, .members-table td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-header > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .dashboard-header select, .save-changes-button, .invite-button {
    margin-bottom: 10px;
    flex: 1 1 auto;
  }

  .save-changes-button {
    position: relative;
    bottom: auto;
    width: auto;
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.invite-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.invite-popup h3 {
  margin-bottom: 10px;
}

.invite-popup input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.invite-popup button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

.invite-popup button:hover {
  background-color: #0056b3;
}
