.personal-area {
  padding: 30px;
  background: white;
  height: max-content;
  margin: 50px 0;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.personal-area h2 {
  margin-top: -15px;
  text-align: center;
  margin-bottom: 20px;
  color: #007BFF; /* Light blue color for the main heading */
}

section {
  margin-bottom: 30px;
}

.details-view p,
.details-form input {
  margin: 10px 0;
}

.details-form input {
  width: calc(100% - 20px);
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.edit-button,
.save-button,
.upload-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF; /* Light blue color for buttons */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  align-items: center;
}

.upload-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;
}

.events-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.events-table th,
.events-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.events-table th {
  background-color: #E6F2FF; /* Light blue background for table headers */
  font-weight: bold;
  color: #007BFF; /* Darker blue text color for table headers */
  text-align: center;
}

.events-table tr:nth-child(even) {
  background-color: #F0F8FF; /* Light blue background for even rows */
}

.bold {
  background: #ff000042;
  border: 2px solid #ff0000;
  border-radius: 10px;
  padding: 5px;
}

.uploaded-file {
  margin-top: 10px;
}

.uploaded-file p {
  color: #007BFF; /* Light blue color for uploaded file text */
}

.action-buttons button {
  margin: 2px 0;
  padding: 4px 6px;
  background-color: #007BFF;
  font-size: 8px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.action-buttons button:hover {
  background-color: #0056b3;
}

.date-picker-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.date-picker-save, .date-picker-cancel {
  margin: 5px 0;
  padding: 8px 12px;
  background-color: #007BFF;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.occupied-date {
  background-color: #ffdddd !important;
  color: #ff0000 !important;
}

.approved-deposit-date {
  background-color: #ddffdd !important;
  color: #008000 !important;
}

.approved-paid-date {
  background-color: #ddddff !important;
  color: #0000ff !important;
}

.custom-datepicker .react-datepicker__day--occupied-date,
.custom-datepicker .react-datepicker__day--approved-deposit-date,
.custom-datepicker .react-datepicker__day--approved-paid-date {
  border-radius: 50%;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.blocked-date {
  background-color: #ff6666;
}
