.appeal {
  width: 85%;
  height: max-content;

  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 50px;
}

.appeal h2 {
  text-align: center;
  margin-bottom: 20px;
}

.appeal form {
  display: flex;
  flex-direction: column;
}

.appeal input,
.appeal textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.back-button,
.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background: #4b4b4b;
  color: white;
  border: none;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #3669f4;
}

.back-button:hover {
  background-color: #d32f2f;
}
