.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .popup-content p {
    margin: 10px 0;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .popup-buttons button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007BFF;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .popup-buttons button:hover {
    background-color: #0056b3;
  }
  