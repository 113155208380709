.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}



.menu li {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu a {
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu li:hover {
  background-color: #f1f1f1;
}
.bolder {
  font-weight: bold;
  background: rgb(230, 230, 230);
  animation: flicker 0.3s 3;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



